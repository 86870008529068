import React, { useState, useEffect } from "react";
import { search } from "../actions/general";
import { connect } from "react-redux";
import classNames from "classnames";
import CategorySquare from "./Category/CategorySquare";
import ProductSquare from "./Product/ProductSquare";
import Title from "./layout/Title";

const Search = ({ general, search }) => {
  const [searchValue, setSearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);

  useEffect(() => {
    search("");
  }, [search]);

  const searchUpdate = async (e) => {
    const sval = e.target.value;
    setSearch(sval);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(function () {
        search(sval);
      }, 1000)
    );
  };

  return (
    <>
      <Title title="Search" />
      <div className="container mainContent searchWrapper">
        <div style={{ width: "400px", margin: "25px auto" }}>
          <p className={classNames({ "control has-icons-left": true, "is-loading": searchValue && general.search.inProgress })}>
            <input autoFocus className="input" type="text" name="search" value={searchValue} onChange={searchUpdate} />
            <span className="icon is-small is-left">
              <i className="fas fa-search"></i>
            </span>
          </p>
        </div>
      </div>

      {searchValue && !general.search.inProgress && general.search.categories && (
        <div className="max-container">
          <div className="fcontainer">
            <div className="grid-row">
              {general.search.categories.map((c, index) => (
                <CategorySquare category={c} key={index} />
              ))}
            </div>
          </div>
        </div>
      )}

      {searchValue && !general.search.inProgress && general.search.products && (
        <div className="max-container">
          <div className="fcontainer">
            <div className="grid-row">
              {general.search.products
                .filter((p) => p.categories && p.categories.length)
                .map((p, index) => (
                  <ProductSquare key={index} category_uid={p.categories[0].UID} product={p} />
                ))}
            </div>
          </div>
        </div>
      )}

      {searchValue && general.search.noResults && <div className="container">No results</div>}
    </>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default connect(mapStateToProps, { search })(Search);
