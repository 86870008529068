import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../ImageLoader";
import { separateIt } from "../../utils/misc";

export default function CategoryTiles({ category, general, category_uid }) {
  const tileSizesOdd = [5, 5, 2];
  const tileSizesEven = [2, 5, 5];
  return (
    <div className="mainContent">
      {(!category.child_categories || !category.child_categories.length) && <p style={{ paddingTop: 30, textAlign: "center" }}>No child categories found.</p>}
      {category.child_categories && category.child_categories.length > 0 && (
        <div className="container space-article">
          {separateIt(category.child_categories, 3).map((cats, i) => {
            return (
              <div key={`${category_uid}_${i}`} className="categories-tiles columns">
                {cats.map((c, index) => {
                  const cat = general.categories[c.UID];
                  return (
                    <div key={index} className={`column is-${(i + 1) % 2 ? tileSizesOdd[index] : tileSizesEven[index]}`}>
                      <div>
                        <Link to={cat.path}>
                          <div>{cat.Image ? <ImageLoader alt="Category" src={`${process.env.REACT_APP_API_URL}${cat.Image.url}`} /> : <i className="fas fa-image fa-9x"></i>}</div>
                          <h4>{cat.Title}</h4>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
