import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

function getLink(m, key, general, history, setdropdownState, i, onClick, level, productCategoryUID) {
  const onMouseUp = () => {
    setdropdownState(null);
  };
  const onMouseEnter = () => {
    if (m.children && m.children.length) setdropdownState(i);
  };
  const onMouseLeave = () => {
    if (m.children && m.children.length) setdropdownState(null);
  };
  const isActive = (m) => {
    let urlPath = productCategoryUID && general.categories[productCategoryUID] ? general.categories[productCategoryUID].path : history.location.pathname;
    return urlPath === m.link || urlPath.includes(`${m.link}/`) || (m.children && m.children.length && m.children.find((c) => urlPath === c.link || urlPath.includes(`${c.link}/`)));
  };

  switch (m.__component) {
    case "misc.placeholder-menu-item":
      return (
        <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={classNames({ "navbar-placeholder": true, "navbar-item": true, active: isActive(m), "navbar-link": m.children && m.children.length })}>
          {m.Title}
        </span>
      );
    default:
      return (
        <Link onClick={onClick} onMouseUp={onMouseUp} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} key={key} to={m.link} className={classNames({ "navbar-item": true, active: isActive(m), "navbar-link": m.children && m.children.length })}>
          {level === 2 && m.image && <img alt="" src={m.image} />}
          {m.Title}
        </Link>
      );
  }
}

const Header = ({ general, history }) => {
  const [dropdownState, setdropdownState] = useState(null);
  const [burgerState, setburgerState] = useState(false);
  const onLinkClick = () => {
    setburgerState(false);
  };

  const urlChunks = history.location.pathname
    .toLowerCase()
    .split("/")
    .filter((c) => c);

  let productCategoryUID = urlChunks[0] === "product" ? urlChunks[1] : false;

  return (
    <header className="container">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link onClick={onLinkClick} className="navbar-item" to="/">
            {general.header.Logo && <img alt="" src={`${process.env.REACT_APP_API_URL}${general.header.Logo.url}`} />}
          </Link>
          <button
            onClick={() => {
              setburgerState(!burgerState);
            }}
            className={classNames({ "navbar-burger burger": true, "is-active": burgerState })}
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div id="navbar-collapse-button" className={classNames({ "navbar-menu": true, "is-active": burgerState })}>
          <div className="navbar-end">
            {general.header.Menu.map((m, i) => {
              return m.children && m.children.length ? (
                <div key={i} className={classNames({ "navbar-item": true, "has-dropdown": true, "is-active": dropdownState === i })}>
                  {getLink(m, i, general, history, setdropdownState, i, onLinkClick, 1, productCategoryUID)}
                  <div
                    className="navbar-dropdown"
                    onMouseEnter={() => {
                      setdropdownState(i);
                    }}
                    onMouseLeave={() => {
                      setdropdownState(null);
                    }}
                  >
                    {m.children.map((m2, key2) => {
                      return getLink(m2, key2, general, history, setdropdownState, i, onLinkClick, 2, productCategoryUID);
                    })}
                  </div>
                </div>
              ) : (
                getLink(m, i, general, history, setdropdownState, i, onLinkClick, 1, productCategoryUID)
              );
            })}
            <span className="navbar-item">
              <Link onClick={onLinkClick} to="/search">
                <i className="i-search orangecolor fas fa-search"></i>
              </Link>
            </span>
          </div>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default withRouter(connect(mapStateToProps, {})(Header));
