import strapi from "../api";
import * as types from "./types";
import showdown from "showdown";
import history from "./../history";

//Get product info
export const loadProduct = (uid) => async (dispatch) => {
  try {
    dispatch({
      type: types.PRODUCT_LOADING,
    });

    const product = (await strapi.request("GET", `/products?UID=${uid}`))[0];

    const recommended = await strapi.request("POST", "/graphql", {
      data: {
        query: `query { 
          products(where: {UID: "${uid}"}) {
            categories(where: {Published: true}) {UID}
            recommended(where: {Published: true}) {Title UID Gallery {url formats} categories {UID} }
           }
        }`,
      },
    });

    if (!product.Published) throw new Error();
    if (!recommended.data.products[0] || !recommended.data.products[0].categories.length) throw new Error();
    const converter = new showdown.Converter();
    converter.setOption("simpleLineBreaks", true);

    dispatch({
      type: types.PRODUCT_LOADED,
      payload: { ...product, recommended: recommended.data.products[0] ? recommended.data.products[0].recommended : null, Description: converter.makeHtml(product.Description) },
    });
  } catch (error) {
    history.push("/404");
  }
};
