import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ContentModule from "../ContentModule";
import HomeCarousel from "./HomeCarousel";
import HomeSlider from "./HomeSlider";
import { contactFormToggle } from "./../../actions/general";

const Home = ({ general: { home }, contactFormToggle }) => {
  return (
    <>
      <Helmet>
        <title>{`${home.SEOPrefix}${home.SEO.SEO_Title}`}</title>
        {home.SEO.SEO_Description && <meta name="description" content={home.SEO.SEO_Description} />}
      </Helmet>
      <HomeSlider title={home.SliderTitle} action={contactFormToggle} actionButtonText={home.SliderActionButtonText} slides={home.Slides} slideimg1={home.slideimg1} />
      <div className="container mainContent">
        <HomeCarousel title={home.Carousel.Title} subtitle={home.Carousel.Subtitle} recommended={home.Carousel.products} />
        {home.ContentModules &&
          home.ContentModules.map((c, i) => {
            return <ContentModule key={i} Title={c.Title} Text={c.Text} URL={c.Link} Image={c.Image} />;
          })}

        {home.Partners && (
          <div className="brand-tiles space-article">
            <h3>{home.PartnersTitle}</h3>
            <div className="columns">
              {home.Partners.map((pblock, i) => {
                return (
                  <div className="column" key={i}>
                    <div>
                      {pblock.Partner.filter((p) => p.Logo).map((p, i) => {
                        return p.FullWidth ? (
                          <div key={i}>
                            <img alt="Partner" src={`${process.env.REACT_APP_API_URL}${p.Logo.url}`} />
                          </div>
                        ) : (
                          <img key={i} alt="Partner" src={`${process.env.REACT_APP_API_URL}${p.Logo.url}`} />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default connect(mapStateToProps, { contactFormToggle })(Home);
