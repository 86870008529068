import React, { useState, useCallback, Fragment } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ImageLoader from "../ImageLoader";

export default function HomeSlider({ title, actionButtonText, action, slides }) {
  const [checkState, setCheckState] = useState(null);
  const [swiped, setSwiped] = useState(false);

  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleOnItemClick = useCallback(
    (e) => {
      if (swiped) {
        e.stopPropagation();
        e.preventDefault();
        setSwiped(false);
      }
    },
    [swiped]
  );

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    adaptiveHeight: true,
  };

  if (!slides || !slides.length) return <></>;
  return (
    <>
      <div className="hero bluebgcolor is-small sliderWrapper">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-mobile is-centered">
              <div id="herotitlealign" className="column is-5">
                <h1 id="hero-title-h1" className="title animated bounceInLeft faster" dangerouslySetInnerHTML={{ __html: title }}></h1>
                <button onClick={action} className="button button-form is-primary is-large animated bounceInLeft">
                  {actionButtonText}
                </button>
              </div>

              <div className="column is-7 is-hidden-mobile">
                <div className="single-item main-show">
                  <Slider onSwipe={handleSwiped} className="mainslider" {...settings}>
                    {slides.map((slide, i) => {
                      return (
                        <div onClickCapture={handleOnItemClick} key={i} className="wrapper-slider-img">
                          <div className="checkmarksnslides">
                            {slide.CheckMarks.map((c, j) => {
                              return (
                                <Fragment key={j}>
                                  <i
                                    onMouseEnter={() => {
                                      setCheckState(j);
                                    }}
                                    onMouseLeave={() => {
                                      setCheckState(null);
                                    }}
                                    key={j}
                                    style={{ left: `${c.xAxis}%`, top: `${c.yAxis}%` }}
                                    className="checkMark hint fas fa-check-circle animated slideInDown"
                                  >
                                    <span style={{ visibility: checkState === j ? "visible" : "hidden" }}>{c.Text}</span>
                                  </i>
                                </Fragment>
                              );
                            })}
                            <Link to={`/product/${slide.product.categories[0].UID}/${slide.product.UID}`}>
                              <div className="imgSlide">
                                <ImageLoader alt="Slide" src={`${process.env.REACT_APP_API_URL}${slide.Image.url}`} />
                              </div>
                              <div className="title-main-show">
                                <p className="subtitle is-3">{slide.Title}</p>
                                <hr />
                                <p className="subtitle is-5">{slide.Subtitle}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>

                  <div className="circless is-hidden-mobile">
                    <div className="circle-solid circleMedium"></div>
                    <div className="circle-solid circleExtraSmall"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
