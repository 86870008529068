import React, { useCallback, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ImageLoader from "../ImageLoader";

export default function HomeCarousel({ recommended, title, subtitle }) {
  const [swiped, setSwiped] = useState(false);
  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleOnItemClick = useCallback(
    (e) => {
      if (swiped) {
        e.stopPropagation();
        e.preventDefault();
        setSwiped(false);
      }
    },
    [swiped]
  );

  const settings = {
    dots: false,

    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  if (!recommended || !recommended.length) return <></>;

  return (
    <>
      <div className="carousel-title">
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
      </div>
      <div className="container homeCarouselWrapper">
        <Slider onSwipe={handleSwiped} className="carousel" {...settings}>
          {recommended
            .filter((r) => r.categories && r.categories.length)
            .map((r, i) => {
              return (
                <div onClickCapture={handleOnItemClick} key={i} className="wrapper-carousel-img">
                  <Link to={`/product/${r.categories[0].UID}/${r.UID}`}>
                    <div className="carouselItem">
                      {r.Gallery && r.Gallery.length > 0 && r.Gallery[0].formats ? <ImageLoader alt="Recommended Product" src={`${process.env.REACT_APP_API_URL}${r.Gallery[0].formats.thumbnail.url}`} /> : <i className="fas fa-image fa-8x"></i>}
                      <div className="grid-item-content nobg">{r.Title}</div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
}
