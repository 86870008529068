import strapi from "../api";
import { setAlert } from "./alert";
import loadImage from "./../utils/loadImage";
import * as types from "./types";
import showdown from "showdown";

const getLink = (m, categories = []) => {
  let singlePagesMapping = { Repairs: "repairs", AboutUs: "about-us", Contact: "contact" };
  switch (m.__component) {
    case "misc.category-link":
      return categories[m.category.UID] ? `${categories[m.category.UID].path}` : "/404";
    case "misc.single-type-link":
      return "/" + (singlePagesMapping[m.ChooseSingleTypePage] || m.ChooseSingleTypePage.toLowerCase());
    case "misc.page-link":
      return `/page/${m.page.id}`;
    default:
      return "#!";
  }
};

export const search = (string) => async (dispatch) => {
  if (!string) {
    dispatch({
      type: types.SEARCH_CLEAR,
    });
    return;
  }
  dispatch({
    type: types.SEARCH_STARTED,
  });
  const products = await strapi.getEntries("products", { Published: true, Title_contains: string, _limit: -1 });
  dispatch({
    type: types.SEARCH_UPDATED,
    payload: { products, search: string },
  });
};

export const contactFormToggle = () => async (dispatch) => {
  dispatch({
    type: types.CONTACT_FORM_TOGGLE,
  });
};

export const contactFormShowPage = (page) => async (dispatch) => {
  dispatch({
    type: types.CONTACT_FORM_SHOW_PAGE,
    payload: page,
  });
};

//Get general info
export const loadGeneral = () => async (dispatch) => {
  const converter = new showdown.Converter();
  try {
    let [home, brandsRaw, repairs, about, contact, footer, header, cats] = await Promise.all([strapi.request("POST", "/graphql", { data: { query: `query { home { SliderTitle SliderActionButtonText SEOPrefix SEO {SEO_Title SEO_Description} Carousel {Title Subtitle products {UID Title categories {UID} Gallery {url formats} } } Slides {Title Subtitle Image {url height width} product {UID categories {UID} } CheckMarks {xAxis yAxis Text}  } PartnersTitle Partners {Partner {Logo {url} FullWidth}} ContentModules {Title Text Link {URL Name} Image {url}} } } ` } }), strapi.getEntries("brands"), strapi.getEntries("repairs"), strapi.getEntries("About-Us"), strapi.getEntries("contact"), strapi.getEntries("footer"), strapi.getEntries("header"), strapi.request("POST", "/graphql", { data: { query: `query { categories(where: {Published: true, _limit:-1}) {UID productsCount Description Format Title Image {url} brand {id Title Logo {url}} child_categories(where: {Published: true}) {UID} parent_category {UID}} }` } })]);
    const categories = {};
    cats.data.categories.forEach((c) => {
      categories[c.UID] = { ...c, Description: converter.makeHtml(c.Description) };
    });

    let brands = {};
    brandsRaw.forEach((b) => (brands[b.id] = b));

    home = home.data.home;
    if (home.Slides && home.Slides.length && home.Slides[0].Image.url) {
      await loadImage(`${process.env.REACT_APP_API_URL}${home.Slides[0].Image.url}`, false);
    }

    Object.keys(categories).forEach(function (key) {
      const catPaths = [];
      if (categories[key].parent_category) {
        let parent_category = categories[categories[key].parent_category.UID];
        while (parent_category) {
          catPaths.unshift(parent_category.UID);
          parent_category = parent_category.parent_category ? categories[parent_category.parent_category.UID] : null;
        }
      }
      categories[key].path = `/category/${catPaths.join("/")}${catPaths.length ? "/" : ""}${categories[key].UID}`;
    });

    header.Menu = header.Menu.map((m) => {
      const processedMenuItem = { ...m, link: getLink(m, categories) };
      if (m.category && m.category.Image && m.category.Image.formats) processedMenuItem.image = `${process.env.REACT_APP_API_URL}${m.category.Image.formats.thumbnail.url}`;
      return processedMenuItem;
    });

    header.Menu = header.Menu.filter((m) => !m.ParentMenuItem).map((m) => {
      return { ...m, children: header.Menu.filter((m2) => m2.ParentMenuItem && m2.ParentMenuItem === m.Title) };
    });

    if (!home.SEOPrefix) home.SEOPrefix = "Serva-Lite: ";
    if (!home.SEO) home.SEO = {};
    if (!home.SEO.SEO_Title) home.SEO.SEO_Title = "Home";
    if (!contact.SEO) contact.SEO = {};
    if (!contact.SEO.SEO_Title) contact.SEO.SEO_Title = contact.Title;
    if (!about.SEO) about.SEO = {};
    if (!about.SEO.SEO_Title) about.SEO.SEO_Title = about.Title;
    if (!repairs.SEO) repairs.SEO = {};
    if (!repairs.SEO.SEO_Title) repairs.SEO.SEO_Title = repairs.Title;

    //converter.setOption("simpleLineBreaks", true);
    about.Text = converter.makeHtml(about.Text);
    repairs.Text = converter.makeHtml(repairs.Text);

    dispatch({
      type: types.GENERAL_LOADED,
      payload: { home, brands, repairs, about, contact, footer, header, categories },
    });
  } catch (error) {
    console.log(error);
    dispatch(setAlert("Loading error", "is-danger"));
  }
};
