import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loadCategory } from "../../actions/category";
import { lastChunk } from "../../utils/misc";
import Breadcrumbs from "../layout/Breadcrumbs";
import CategoryDefault from "./CategoryDefault";
import CategoryBlocks from "./CategoryBlocks";
import CategorySquares from "./CategorySquares";
import CategoryTiles from "./CategoryTiles";
import CategoryProducts from "./CategoryProducts";

const Category = ({ loadCategory, category, general, history }) => {
  const category_uid = lastChunk(history.location.pathname);
  useEffect(() => {
    let catIteration = null;
    history.location.pathname
      .toLowerCase()
      .split("/")
      .filter((c) => c && c !== "category")
      .forEach((chunk) => {
        if (!general.categories[chunk]) {
          history.push("/404");
          return;
        } else if (catIteration && general.categories[chunk].parent_category.UID !== catIteration.UID) {
          history.push("/404");
          return;
        } else catIteration = general.categories[chunk];
      });

    if (general.categories[category_uid].Format === "DisplayProducts" && (!category.loaded || category.UID !== category_uid)) {
      loadCategory(category_uid, general.categories[category_uid].Format, general.brands);
    }
  }, [loadCategory, history, general.categories, general.brands, category_uid, category.UID, category.loaded]);

  const renderCategoryFormat = () => {
    switch (general.categories[category_uid].Format) {
      case "DisplayChildCategoriesTiles":
        return <CategoryTiles category={general.categories[category_uid]} category_uid={category_uid} general={general} />;
      case "DisplayChildCategoriesSquares":
        return <CategorySquares category={general.categories[category_uid]} category_uid={category_uid} general={general} />;
      case "DisplayChildCategoriesBlocks":
        return <CategoryBlocks category={general.categories[category_uid]} category_uid={category_uid} general={general} />;
      case "DisplayProducts":
        return <CategoryProducts title={general.categories[category_uid].Title} category={category.loaded > 0 ? category : { ...general.categories[category_uid], loaded: false }} category_uid={category_uid} general={general} />;
      default:
        return <CategoryDefault category={general.categories[category_uid]} category_uid={category_uid} general={general} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${general.home.SEOPrefix}${category.SEO && category.SEO.SEO_Title ? category.SEO.SEO_Title : category.Title}`}</title>
        {category.SEO && category.SEO.SEO_Description && <meta name="description" content={category.SEO.SEO_Description} />}
      </Helmet>
      {general.categories[category_uid] !== null && <Breadcrumbs disableLast={true} categoryUID={category_uid} />}
      {renderCategoryFormat()}
    </>
  );
};

const mapStateToProps = (state) => ({
  category: state.category,
  general: state.general,
});

export default withRouter(connect(mapStateToProps, { loadCategory })(Category));
