import React from "react";
import { Link } from "react-router-dom";

export default function CategoryDefault({ category, general, category_uid }) {
  return (
    <div className="container mainContent">
      <div dangerouslySetInnerHTML={{ __html: category.Description }} />
      <div>Child Categories:</div>
      {category.child_categories.map((c, index) => {
        return (
          <div key={index}>
            <Link to={general.categories[c.UID].path}>{c.Title}</Link>
          </div>
        );
      })}
      <div>Products:</div>
      {category.products.map((p, index) => {
        return (
          <div key={index}>
            <Link to={`/product/${category_uid}/${p.UID}`}>{p.Title}</Link>
          </div>
        );
      })}
    </div>
  );
}
