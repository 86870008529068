import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Obfuscate from "react-obfuscate";
import { contactFormToggle } from "./../actions/general";

const Contact = ({ general: { contact, home }, contactFormToggle }) => {
  return (
    <>
      <Helmet>
        <title>{`${home.SEOPrefix}${contact.SEO.SEO_Title}`}</title>
        {contact.SEO.SEO_Description && <meta name="description" content={contact.SEO.SEO_Description} />}
      </Helmet>
      <section className="hero bluebgcolor hero-title-center">
        <div className="hero-body">
          <h2>{contact.Title}</h2>
        </div>
        <div className="circle circleBig"></div>
        <div className="circle circleSmall"></div>
      </section>

      <div className="container">
        <section className="columns btn-slogan">
          <div className="column is-hidden-mobile is-7">
            {contact.ActionButtonText && (
              <button onClick={contactFormToggle} className="button is-primary is-large">
                {contact.ActionButtonText}
              </button>
            )}
          </div>
          <div className="column is-hidden-mobile is-5">
            <h4>{contact.Subtitle}</h4>
          </div>
        </section>

        <section className="columns contact">
          <div className="column is-7">
            <div>
              {contact.Phone && (
                <div>
                  <span>
                    <i className="fas fa-phone"></i>
                  </span>
                  <span>
                    <Obfuscate tel={contact.Phone} />
                  </span>
                </div>
              )}
              <div>
                <span>
                  <i className="fas fa-envelope-open-text"></i>
                </span>
                <span>
                  <Obfuscate email="info@serva-lite.com" />
                </span>
              </div>
              {contact.Address && (
                <div>
                  <span>
                    <i className="fas fa-map-marker-alt"></i>
                  </span>
                  <span>{contact.Address}</span>
                </div>
              )}
            </div>
          </div>

          {contact.Schedule && (
            <div className="column is-5">
              <div>
                <table>
                  <tbody>
                    {contact.Schedule.map((s, i) => {
                      return (
                        <tr key={i}>
                          <td>{s.Day}</td>
                          <td>{s.Time}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </section>
      </div>

      <div dangerouslySetInnerHTML={{ __html: contact.Map }} />
    </>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default connect(mapStateToProps, { contactFormToggle })(Contact);
