import strapi from "../api";
import * as types from "./types";
import showdown from "showdown";
import _ from "lodash";
import history from "./../history";

//Get category info
export const loadCategory = (uid, format, brands = []) => async (dispatch) => {
  try {
    dispatch({
      type: types.CATEGORY_LOADING,
    });

    let category = null;
    if (format === "DisplayProducts") {
      const categories = await strapi.getEntries("categories", { UID: uid });
      category = categories && categories.length ? categories[0] : null;
      category.Attributes = category.Attributes && category.Attributes.length ? category.Attributes[0].__component : null;

      const filters = {};
      category.products.forEach((p) => {
        if (p.brand && brands[p.brand] && (!filters["Brand"] || !filters["Brand"][p.brand])) {
          if (!filters["Brand"]) filters["Brand"] = {};
          filters["Brand"][p.brand] = brands[p.brand].Title;
        }
        if (p.Attributes && p.Attributes.length && p.Attributes[0].__component === category.Attributes) {
          const fields = _.omit(p.Attributes[0], ["id", "__component", "InteriorsGallery"]);
          Object.keys(fields)
            .filter((fName) => fields[fName] && fName && !fName.toLowerCase().includes("gallery"))
            .forEach((fName) => {
              if (typeof fields[fName] === "object") {
                if (!Array.isArray(fields[fName]) && fields[fName].id && fields[fName].Title) {
                  if (!filters[fName] || !filters[fName][fields[fName]]) {
                    if (!filters[fName]) filters[fName] = {};
                    filters[fName][fields[fName].id] = fields[fName].Title;
                  }
                }
                if (Array.isArray(fields[fName])) {
                  fields[fName].forEach((f) => {
                    if (!Array.isArray(f) && f.id && f.Title) {
                      if (!filters[fName]) filters[fName] = {};
                      filters[fName][f.id] = f.Title;
                    }
                  });
                }
              } else {
                if (!filters[fName] || !filters[fName][fields[fName]]) {
                  if (!filters[fName]) filters[fName] = {};
                  filters[fName][fields[fName]] = fields[fName];
                }
              }
            });
        }
      });
      category.filters = filters;
    } else {
      const categories = await strapi.request("POST", "/graphql", { data: { query: `query { categories(where: {UID: "${uid}"}) {UID Attributes {__typename} Published Title Description Image {url} SEO {SEO_Title SEO_Description} Format child_categories(where: {Published: true}) {UID Title Image {url} brand {id Title Logo {url}} } products(where: {Published: true}) {UID brand {id Title}  Title Gallery {formats} } parent_category {id}} }` } });
      category = categories.data.categories[0];
    }

    if (!category.Published) throw new Error();
    const converter = new showdown.Converter();
    converter.setOption("simpleLineBreaks", true);

    dispatch({
      type: types.CATEGORY_LOADED,
      payload: { ...category, Description: converter.makeHtml(category.Description) },
    });
  } catch (error) {
    history.push("/404");
  }
};
