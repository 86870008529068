import React from "react";

export default function Title({ title, subtitle }) {
  return (
    <div className="titleWrap">
      <article>
        <div>
          <h2>
            {title}
            <span className="dot is-hidden-mobile"></span>
          </h2>
        </div>
      </article>
      {subtitle}
    </div>
  );
}
