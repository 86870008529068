import * as types from "../actions/types";

const initialState = {
  loaded: 0,
  parent_category: null,
  products: [],
  title: "",
  text: "",
  SEO: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CATEGORY_LOADING: {
      return {
        ...state,
        loaded: -1,
      };
    }
    case types.CATEGORY_LOADED: {
      return {
        ...state,
        ...action.payload,
        loaded: 1,
      };
    }
    default:
      return state;
  }
}
