import * as types from "../actions/types";

const initialState = {
  loaded: false,
  contactFormVisible: false,
  contactFormPage: false,
  search: { inProgress: false, noResults: false, products: null, categoeries: null },
  home: {},
  brands: [],
  repairs: {},
  about: {},
  contact: {},
  footer: {},
  header: {},
  categories: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SEARCH_CLEAR: {
      return { ...state, search: { inProgress: false, noResults: false, products: null, categoeries: null } };
    }
    case types.SEARCH_STARTED: {
      return { ...state, search: { inProgress: true, noResults: false, products: null, categoeries: null } };
    }
    case types.SEARCH_UPDATED: {
      const categories = Object.keys(state.categories)
        .filter((c) => state.categories[c].Title.toLowerCase().includes(action.payload.search))
        .map((c) => state.categories[c]);
      const { products } = action.payload;
      return {
        ...state,
        search: {
          inProgress: false,
          noResults: (!products || !products.length) && (!categories || !categories.length),
          products,
          categories,
        },
      };
    }
    case types.CONTACT_FORM_TOGGLE: {
      return { ...state, contactFormPage: false, contactFormVisible: !state.contactFormVisible };
    }
    case types.CONTACT_FORM_SHOW_PAGE: {
      return { ...state, contactFormVisible: true, contactFormPage: action.payload };
    }
    case types.GENERAL_LOADED: {
      const { home, brands, repairs, about, contact, footer, header, categories } = action.payload;
      return {
        ...state,
        home,
        brands,
        repairs,
        about,
        contact,
        footer,
        header,
        categories,
        loaded: true,
      };
    }
    default:
      return state;
  }
}
