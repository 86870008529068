import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ general, categoryUID, disableLast = false }) => {
  if (!general.loaded) return <></>;
  let category = general.categories[categoryUID];
  if (!category) return <></>;
  const elements = [category];
  while (category.parent_category) {
    category = general.categories[category.parent_category.UID];
    elements.unshift(category);
  }

  return (
    <div className="container">
      <div className="bd-breadcrumb">
        <nav className="breadcrumb has-succeeds-separator is-small" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {elements.map((e, index) => {
              return disableLast && index === elements.length - 1 ? (
                <li key={index}>
                  <span>{e.Title}</span>
                </li>
              ) : (
                <li key={index}>
                  <Link to={e.path}>{e.Title}</Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default withRouter(connect(mapStateToProps, {})(Breadcrumbs));
