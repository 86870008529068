import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../ImageLoader";

export default function CategoryBlocks({ category, general, category_uid }) {
  return (
    <div className="mainContent">
      {(!category.child_categories || !category.child_categories.length) && <p style={{ paddingTop: 30, textAlign: "center" }}>No child categories found.</p>}
      {category.child_categories && category.child_categories.length > 0 && (
        <section className="container space-article">
          <div className="columns categories is-variable">
            {category.child_categories.map((c, index) => {
              const cat = general.categories[c.UID];
              return (
                <div key={index} className="column">
                  <Link to={cat.path}>
                    <div className="catBlock">
                      <h2>{cat.Title}</h2>
                      {cat.Image && <ImageLoader alt="Category" src={`${process.env.REACT_APP_API_URL}${cat.Image.url}`} />}
                      <div className="circle circleBig"></div>
                      <div className="circle circleMedium"></div>
                      <p>
                        More<i className="fas fa-chevron-right"></i>
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </div>
  );
}
