const separateIt = (arr, size) => {
  var newArr = [];
  for (var i = 0; i < arr.length; i += size) {
    var sliceIt = arr.slice(i, i + size);
    newArr.push(sliceIt);
  }
  return newArr;
};

const lastChunk = (url) => {
  var parts = url.split("/");
  return parts.pop() || parts.pop();
};

const extractFields = (extra) => {
  const returnArray = [];

  Object.keys(extra)
    .filter((fieldName) => !["id", "__component"].includes(fieldName) && extra[fieldName] && fieldName && !fieldName.toLowerCase().includes("gallery"))
    .forEach((fieldName) => {
      const fieldNameUnCameled = fieldName.replace(/([a-z])([A-Z])/g, "$1 $2");
      let field = extra[fieldName];
      let field_type = typeof field;
      if (field_type === "object" && Array.isArray(field)) field_type = "array";

      switch (field_type) {
        case "string":
          if (field) returnArray.push({ field: fieldNameUnCameled, value: field });
          break;
        case "object":
          if (field.Title) returnArray.push({ field: fieldNameUnCameled, value: field.Title });
          break;

        case "array":
          const str = field
            .filter((f) => f.Title)
            .map((f) => f.Title)
            .join(", ");

          if (str) returnArray.push({ field: fieldNameUnCameled, value: str });
          break;

        default:
          break;
      }
    });
  return returnArray;
};

const loadImage = (url, crossOrigin) => {
  const image = new Image();

  // Support cross origin requests
  if (crossOrigin) image.crossOrigin = crossOrigin;

  return new Promise((resolve, reject) => {
    // Load Handler
    const loaded = (event) => {
      // Cleanup our image element, we no longer need it
      unbindEvents(image);
      // Fulfill our promise with the event image element, even in older browsers
      resolve(event.target || event.srcElement);
    };

    // Error Handler
    const errored = (error) => {
      // Cleanup our image element, we no longer need it
      unbindEvents(image);
      // Forward our error to the user
      reject(error);
    };

    // Set our handlers
    image.onload = loaded;
    image.onerror = errored;
    image.onabort = errored;

    // Tell the browser we are ready to begin downloading
    image.src = url;
  });
};

function unbindEvents(image) {
  // Reset callbacks
  image.onload = null;
  image.onerror = null;
  image.onabort = null;

  try {
    // Some browsers need you to remove the src
    // in order to garbage collect the image object
    delete image.src;
  } catch (e) {
    // Safari's strict mode throws, ignore
  }
}

export { separateIt, lastChunk, extractFields, loadImage };
