//alerts, modals
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_MODAL = "SET_MODAL";
export const REMOVE_MODAL = "REMOVE_MODAL";

//General
export const GENERAL_LOADED = "GENERAL_LOADED";
export const CONTACT_FORM_TOGGLE = "CONTACT_FORM_TOGGLE";
export const CONTACT_FORM_SHOW_PAGE = "CONTACT_FORM_SHOW_PAGE";
export const SEARCH_STARTED = "SEARCH_STARTED";
export const SEARCH_UPDATED = "SEARCH_UPDATED";
export const SEARCH_CLEAR = "SEARCH_CLEAR";

//Page
export const PAGE_LOADED = "PAGE_LOADED";
export const PAGE_LOADING = "PAGE_LOADING";

//Category
export const CATEGORY_LOADED = "CATEGORY_LOADED";
export const CATEGORY_LOADING = "CATEGORY_LOADING";

//Product
export const PRODUCT_LOADED = "PRODUCT_LOADED";
export const PRODUCT_LOADING = "PRODUCT_LOADING";
