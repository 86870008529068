import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Title from "./layout/Title";
import { contactFormToggle } from "./../actions/general";
import ImageLoader from "./ImageLoader";

const Repairs = ({ general: { home, repairs }, contactFormToggle }) => {
  return (
    <>
      <Helmet>
        <title>{`${home.SEOPrefix}${repairs.SEO.SEO_Title}`}</title>
        {repairs.SEO.SEO_Description && <meta name="description" content={repairs.SEO.SEO_Description} />}
      </Helmet>
      <Title title={repairs.Title} />
      <section className="container columns repairs">
        <div className="column">
          <h2>{repairs.Subtitle}</h2>
          <p className="articletext" dangerouslySetInnerHTML={{ __html: repairs.Text }} />
          {repairs.ActionButtonText && (
            <button onClick={contactFormToggle} className="button is-primary is-large">
              {repairs.ActionButtonText}
            </button>
          )}
        </div>

        {repairs.Image && (
          <div className="column is-hidden-mobile">
            <ImageLoader alt="Repairs" className="animated rotateInDownRight" src={`${process.env.REACT_APP_API_URL}${repairs.Image.url}`} />
          </div>
        )}
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default connect(mapStateToProps, { contactFormToggle })(Repairs);
