import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { contactFormShowPage } from "./../actions/general";
import ImageLoader from "./ImageLoader";

const About = ({ general: { about, home }, contactFormShowPage }) => {
  return (
    <>
      <Helmet>
        <title>{`${home.SEOPrefix}${about.SEO.SEO_Title}`}</title>
        {about.SEO.SEO_Description && <meta name="description" content={about.SEO.SEO_Description} />}
      </Helmet>
      <section className="hero bluebgcolor">
        <div className="hero-body">
          <div className="container columns about">
            <div className="column">
              <h2 className="is-left">{about.Title}</h2>
              <p className="articletext" dangerouslySetInnerHTML={{ __html: about.Text }}></p>
            </div>
            {about.Image && (
              <div className="column">
                <ImageLoader alt="About" className="animated rotateInDownRight" src={`${process.env.REACT_APP_API_URL}${about.Image.url}`} />
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="container coworkers">
        {about.Employees.map((e, i) => {
          return (
            <div key={i}>
              <div className={classNames({ "columns is-vcentered": true, "is-reversed": i % 2 })}>
                <div className="column is-4">{e.Photo && <ImageLoader alt="Employee" src={`${process.env.REACT_APP_API_URL}${e.Photo.url}`} />}</div>
                <div key={i} className="column">
                  <h3>{`${e.FirstName} ${e.LastName}`}</h3>
                  <h6>{e.Title}</h6>
                  <p dangerouslySetInnerHTML={{ __html: e.Text }}></p>
                  <button
                    onClick={() => {
                      contactFormShowPage(`About us - ask ${e.FirstName} ${e.LastName}`);
                    }}
                    className="button is-primary is-large"
                  >
                    Ask {e.FirstName}
                  </button>
                </div>
              </div>
              {i < about.Employees.length - 1 && <hr />}
            </div>
          );
        })}
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default connect(mapStateToProps, { contactFormShowPage })(About);
