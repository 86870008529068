import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumbs from "../layout/Breadcrumbs";
import { loadProduct } from "../../actions/product";
import SpecsFiles from "./SpecsFiles";
import Certificates from "./Certificates";
import MultiGallery from "./MultiGallery";
import ProductTitle from "./Title";
import Fields from "./Fields";
import { extractFields } from "./../../utils/misc";
import Recommended from "./Recommended";

const Product = ({ loadProduct, product, general, history }) => {
  const { uid, category_uid } = useParams();

  useEffect(() => {
    loadProduct(uid);
  }, [loadProduct, uid]);

  if (!product.loaded)
    return (
      <>
        <Breadcrumbs categoryUID={category_uid} />
        <section className="container">
          <div className="columns is-mobile-reversed">
            <div className="column is-8">
              <div>
                <section className="gallery-wrapper">
                  <div className="single-item product-show preloading"></div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </>
    );

  const { Attributes, SEO, SKU, certificates, Title, Subtitle, Description, Attachments, recommended, brand, Gallery } = product;

  const fields = extractFields({ Brand: brand, SKU: SKU, ...Attributes[0] });

  const galleries = [];
  if (Gallery && Gallery.length) galleries.push({ title: "Product", images: Gallery });
  Attributes.forEach((a) => {
    Object.keys(a)
      .filter((galleryName) => galleryName.toLowerCase().includes("gallery"))
      .forEach((galleryName) => {
        if (a[galleryName] && a[galleryName].ExtraGallery && a[galleryName].ExtraGallery.length)
          galleries.push({
            title: galleryName
              .replace(/([a-z])([A-Z])/g, "$1 $2")
              .replace("Gallery", "")
              .trim(),
            images: a[galleryName].ExtraGallery,
          });
      });
  });

  const descriptionRight = Description ? fields.length <= 3 && Description.replace(/<[^>]*>?/gm, "").length <= 550 : "";

  return (
    <>
      <Helmet>
        <title>{`${general.home.SEOPrefix}${SEO && SEO.SEO_Title ? SEO.SEO_Title : Title}`}</title>
        {SEO && SEO.SEO_Description && <meta name="description" content={SEO.SEO_Description} />}
      </Helmet>
      <Breadcrumbs categoryUID={category_uid} />
      <section className="container">
        <div className="columns is-mobile-reversed">
          {galleries.length > 0 && (
            <div className="column is-8">
              <div>
                <section className="gallery-wrapper">
                  <MultiGallery galleries={galleries} />
                </section>
              </div>
              <Certificates certificates={certificates} />
            </div>
          )}

          <div className={`column is-${galleries.length ? 4 : 12} product-dscr`}>
            <ProductTitle title={Title} subtitle={Subtitle} />
            <Fields fields={fields} />
            {descriptionRight && <p className="product-details" dangerouslySetInnerHTML={{ __html: Description }}></p>}
            {!galleries.length && <Certificates certificates={certificates} />}
          </div>
        </div>
        {!descriptionRight && <p className="product-details" dangerouslySetInnerHTML={{ __html: Description }}></p>}
      </section>

      <SpecsFiles attachments={Attachments} />

      <Recommended recommended={recommended} />
    </>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
  general: state.general,
});

export default withRouter(connect(mapStateToProps, { loadProduct })(Product));
