import React from "react";
import Slider from "react-slick";
import ImageLoader from "../ImageLoader";

export default function Gallery({ images }) {
  if (!images || !images.length) return <></>;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    adaptiveHeight: true,
  };
  return (
    <div className="single-item product-show">
      <Slider {...settings}>
        {images.map((img, i) => {
          const isVideo = img.mime.includes("video");
          return (
            <div key={i}>
              <div style={{ minHeight: isVideo ? 500 : img.height }} className="img">
                {isVideo ? <video src={`${process.env.REACT_APP_API_URL}${img.url}`} controls /> : <ImageLoader alt="gallery" src={`${process.env.REACT_APP_API_URL}${img.url}`} />}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
