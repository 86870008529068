import React, { useState, useRef } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { contactFormToggle } from "./../actions/general";
import { setAlert } from "../actions/alert";
import strapi from "../api";

const ContactForm = ({ general, contactFormToggle, setAlert, history }) => {
  const contactCaptcha = useRef(null);
  const defaultFields = {
    name: { required: false, value: "" },
    company: { required: false, value: "" },
    email: { required: false, value: "" },
    phone: { required: false, value: "" },
    question: { value: "" },
  };

  const [sent, setSent] = useState(0);
  const [fields, setFields] = useState(defaultFields);

  const updateFields = (e) => {
    setFields({
      ...fields,
      [e.target.name]: { required: false, value: e.target.value },
    });
  };

  const isEmailAddress = (str) => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,100})+$/;
    return pattern.test(str);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    const keys = Object.keys(fields);
    for (let index = 0; index < keys.length; index++) {
      const f = fields[keys[index]];
      if (f.hasOwnProperty("required") && !f.value) {
        setFields({ ...fields, [keys[index]]: { ...f, required: true } });
        const fieldName = keys[index].charAt(0).toUpperCase() + keys[index].slice(1);
        setAlert(`${fieldName} is required`, "is-danger", 3000);
        return;
      }
    }
    if (!isEmailAddress(fields.email.value)) {
      setAlert(`Valid email is required`, "is-danger", 3000);
      return;
    }
    setSent(-1);
    contactCaptcha.current.execute();
  };

  const onCaptchaError = (value) => {
    setAlert(`Captcha verification error`, "is-danger", 3000);
  };

  const onCaptchaChange = async (value) => {
    try {
      await strapi.createEntry("questions", {
        page: general.contactFormPage || (history.location.pathname === "/" ? "home" : history.location.pathname),
        Name: fields.name.value,
        Company: fields.company.value,
        Email: fields.email.value,
        Phone: fields.phone.value,
        Question: fields.question.value,
        verified: value,
      });
      contactCaptcha.current.reset();
      setFields(defaultFields);
      setSent(1);
    } catch (error) {
      contactCaptcha.current.reset();
      setSent(0);
      setAlert("Form submission error", "is-danger", 3000);
    }
  };

  return (
    <>
      <div className={classNames({ "modal form": true, active: general.contactFormVisible })}>
        <div
          className="modal-background"
          onClick={() => {
            setSent(0);
            contactFormToggle();
          }}
        ></div>
        {sent === 1 ? (
          <div className="modal-content">
            <i
              onClick={() => {
                setSent(0);
                contactFormToggle();
              }}
              className="close fas fa-times"
            ></i>
            <h2>Thank you!</h2>
            <p>
              Thank you for choosing Serva-Lite.
              <br />
              We will be in touch with you soon.
            </p>
            <br />
            <p>Regards, Serva-Lite Team</p>
          </div>
        ) : (
          <div className="modal-content">
            <i
              onClick={() => {
                setSent(0);
                contactFormToggle();
              }}
              className="close fas fa-times"
            ></i>
            <form action="">
              <div className={classNames({ control: true, required: fields.name.required })}>
                <input className="input" type="text" placeholder="Name*" name="name" value={fields.name.value} onChange={updateFields} />
              </div>
              <div className={classNames({ control: true, required: fields.company.required })}>
                <input className="input" type="text" placeholder="Company*" name="company" value={fields.company.value} onChange={updateFields} />
              </div>
              <div className={classNames({ control: true, required: fields.email.required })}>
                <input className="input" type="email" placeholder="Email*" name="email" value={fields.email.value} onChange={updateFields} />
              </div>
              <div className={classNames({ control: true, required: fields.phone.required })}>
                <input className="input" type="tel" placeholder="Phone*" name="phone" value={fields.phone.value} onChange={updateFields} />
              </div>
              <div className={classNames({ control: true, required: fields.question.required })}>
                <textarea className="textarea" placeholder="Your question" name="question" value={fields.question.value} onChange={updateFields}></textarea>
              </div>

              <ReCAPTCHA ref={contactCaptcha} size="invisible" sitekey="6LcVNP4UAAAAAGpoIH_62X7y4JgHeWQp1EWAku7s" onChange={onCaptchaChange} onError={onCaptchaError} />

              {
                <button
                  onClick={(e) => {
                    if (sent !== -1) sendMessage(e);
                  }}
                  className="button is-large is-primary"
                >
                  {sent === -1 ? "Sending..." : "Send a message"}
                </button>
              }
            </form>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default withRouter(connect(mapStateToProps, { contactFormToggle, setAlert })(ContactForm));
