import strapi from "../api";
import * as types from "./types";
import showdown from "showdown";
import history from "./../history";

//Get general info
export const loadPage = (id) => async (dispatch) => {
  try {
    dispatch({
      type: types.PAGE_LOADING,
    });

    const page = await strapi.getEntry("pages", id);
    const converter = new showdown.Converter();

    dispatch({
      type: types.PAGE_LOADED,
      payload: { ...page, Text: converter.makeHtml(page.Text) },
    });
  } catch (error) {
    history.push("/404");
  }
};
