import React from "react";
import Main from "./components/Main";
import { Provider } from "react-redux";
import { store } from "./store";
import "./assets/App.sass";
import "./assets/App.css";

const App = () => {
  return (
    <Provider store={store}>
      <div id="App">
        <Main />
      </div>
    </Provider>
  );
};

export default App;
