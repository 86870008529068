import React from "react";

export default function Certificates({ certificates }) {
  if (!certificates || !certificates.length) return <></>;

  return (
    <div className="certificate-logos">
      {certificates.map((c, i) => {
        if (!c.Logo || !c.Logo.url) return <></>;
        return <img key={i} alt="certificate logo" title={c.Title} src={`${process.env.REACT_APP_API_URL}${c.Logo.url}`} />;
      })}
    </div>
  );
}
