import { combineReducers } from "redux";
import alert from "./alert";
import modal from "./modal";
import general from "./general";
import page from "./page";
import category from "./category";
import product from "./product";

const appReducer = combineReducers({
  alert,
  modal,
  general,
  page,
  category,
  product,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
