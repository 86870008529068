import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../ImageLoader";

export default function ProductSquare({ product, category_uid, preloading = false }) {
  return (
    <div className="grid-item prodWrapper">
      <Link className="wrapping-link" to={`/product/${category_uid}/${product.UID}`}></Link>
      <div className="grid-item-wrapper-border">
        <div className="grid-item-container product-list-item">
          <div className="imgwrapper">{product.Gallery && product.Gallery.length && product.Gallery[0].formats ? <ImageLoader alt="Product" src={`${process.env.REACT_APP_API_URL}${product.Gallery[0].formats.thumbnail.url}`} /> : preloading ? <></> : <i className="fas fa-image fa-9x"></i>}</div>
          <div className="grid-item-content nobg">
            <div className="item-title">{product.Title}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
