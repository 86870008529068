import React, { useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { loadGeneral } from "../actions/general";
import { Helmet } from "react-helmet";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Repairs from "./Repairs";
import Product from "./Product";
import Category from "./Category";
import Page from "./Page";
import Search from "./Search";
import Preloader from "./layout/preloader";
import About from "./About";
import Contact from "./Contact";
import ContactForm from "./ContactForm";
import NotFoundPage from "./NotFoundPage";
import Home from "./Home";
import Alert from "./layout/alert";
import ModalComponent from "./../utils/modalComponent";

import { createBrowserHistory as createHistory } from "history";

const customHistory = createHistory();
let lastLocation = customHistory.location.pathname;

const Main = ({ history, general, loadGeneral }) => {
  history.listen((location, action) => {
    if (lastLocation !== location.pathname) window.scrollTo(0, 0);
    lastLocation = location.pathname;
  });

  useEffect(() => {
    loadGeneral();
  }, [loadGeneral]);

  if (!general.loaded) return <Preloader />;

  return (
    <>
      <Helmet>
        <title>{`${general.home.SEOPrefix}${general.home.SEO.SEO_Title}`}</title>
      </Helmet>
      <div id="appWrapper">
        <Alert />
        <ModalComponent />
        <Header />
        <ContactForm />
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route exact path={`${process.env.PUBLIC_URL}/repairs`} component={Repairs} />
          <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={About} />
          <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
          <Route path={`${process.env.PUBLIC_URL}/category/`} component={Category} />
          <Route exact path={`${process.env.PUBLIC_URL}/product/:category_uid/:uid`} component={Product} />
          <Route exact path={`${process.env.PUBLIC_URL}/page/:id`} component={Page} />
          <Route exact path={`${process.env.PUBLIC_URL}/search`} component={Search} />
          <Route exact path="/404" component={NotFoundPage} />
          <Redirect to="/404" />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default withRouter(connect(mapStateToProps, { loadGeneral })(Main));
