import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Gallery from "./Gallery";

export default function MultiGallery({ galleries }) {
  galleries = galleries.filter((g) => g.images && g.images.length);
  if (!galleries || !galleries.length) return <></>;

  return galleries.length === 1 ? (
    <Gallery images={galleries[0].images} />
  ) : (
    <Tabs className="galleryTabs">
      <TabList>
        {galleries.map((g, i) => {
          return <Tab key={i}>{g.title}</Tab>;
        })}
      </TabList>

      {galleries.map((g, i) => {
        return (
          <TabPanel key={i}>
            <Gallery key={i} images={g.images} />
          </TabPanel>
        );
      })}
    </Tabs>
  );
}
