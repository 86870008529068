import React from "react";
import { Link } from "react-router-dom";

const ContentModule = ({ Title, Text, Image, URL }) => {
  return (
    <>
      <hr />
      <div className="columns space-article">
        <div id="title-verical-align" className="column">
          <h2>{Title}</h2>
          {!Image && <span className="circle circleblue"></span>}
          {!Image && <span className="circle circlegray"></span>}
          {Image && <p className="articletext" dangerouslySetInnerHTML={{ __html: Text }} />}
          {URL && Image && (
            <div className="link-more">
              <Link to={URL.URL}>
                {URL.Name}
                <i className="fas fa-chevron-right"></i>
              </Link>
            </div>
          )}
        </div>
        <div className="column">
          {Image && <img alt="Employee" src={`${process.env.REACT_APP_API_URL}${Image.url}`} />}
          {!Image && <p className="articletext" dangerouslySetInnerHTML={{ __html: Text }} />}
          {URL && !Image && (
            <div className="link-more">
              <Link to={URL.URL}>
                {URL.Name}
                <i className="fas fa-chevron-right"></i>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContentModule;
