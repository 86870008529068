import React, { useState } from "react";
import MultiSelect from "react-multi-select-component";
import Title from "../layout/Title";
import ImageLoader from "../ImageLoader";
import ProductSquare from "../Product/ProductSquare";
import classNames from "classnames";
import _ from "lodash";

export default function CategoryProducts({ category, title, general, category_uid }) {
  console.log(category.Title);
  const [selected, setSelected] = useState({});

  const [burgerState, setburgerState] = useState(false);

  return (
    <div>
      {category.Description ? (
        <section className="hero bluebgcolor">
          <div className="hero-body">
            <div className="container columns one-series">
              <div className={`column is-${category.Image ? 8 : 12}`}>
                <h2>{category.Title}</h2>
                <p dangerouslySetInnerHTML={{ __html: category.Description }} />
              </div>
              {category.Image && (
                <div className="column is-4">
                  <ImageLoader alt="Category" src={`${process.env.REACT_APP_API_URL}${category.Image.url}`} />
                </div>
              )}
            </div>
          </div>
        </section>
      ) : (
        <Title title={title} />
      )}

      {!category.loaded && <div style={{ marginBottom: -10, display: "block", width: "100%", height: 62 }}></div>}
      {category.loaded && category.UID === category_uid && category.filters && Object.keys(category.filters).length > 0 && (
        <div style={{ marginBottom: -10 }} className="max-container filter-wrapper">
          <div className="filter">
            <nav className="navbar">
              <button
                onClick={() => {
                  setburgerState(!burgerState);
                }}
                style={{ border: "none", paddingRight: 0, paddingTop: 0 }}
                className={classNames({ burgerFilter: true, "is-active": burgerState })}
                aria-label="menu"
                aria-expanded="false"
              >
                <span className="bf1">
                  <i className="greencolor fas fa-sort-amount-down"></i>Filter
                </span>
                <span className="bf2">
                  <i className="greencolor fas fa-times"></i>
                </span>
              </button>
              <div id="filter-collapse-button" className={classNames({ "navbar-menu": true, "is-active": burgerState })}>
                <div className="navbar-start">
                  <div className="navbar-item">
                    {category.filters &&
                      Object.keys(category.filters).map((f, i) => {
                        return (
                          <MultiSelect
                            autosize={true}
                            hasSelectAll={false}
                            disableSearch={true}
                            overrideStrings={{ selectSomeItems: f.replace(/([a-z])([A-Z])/g, "$1 $2"), allItemsAreSelected: "All" }}
                            key={i}
                            options={Object.keys(category.filters[f]).map((k) => {
                              return { label: category.filters[f][k], value: k };
                            })}
                            value={selected[f]}
                            onChange={(s) => {
                              setSelected({ ...selected, [f]: s });
                            }}
                          />
                        );
                      })}
                  </div>
                  <div id="reset" onClick={() => setSelected({})}>
                    Reset Filter{Object.keys(category.filters).length > 1 && "s"}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      )}
      {!category.loaded || category.UID !== category_uid ? (
        <div className="max-container">
          <div className="fcontainer">
            <div className="grid-row">
              {_.times(category.productsCount, _.constant({ Title: "" })).map((p, index) => (
                <ProductSquare key={index} preloading={true} category_uid={category_uid} product={p} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="max-container">
          {category.products && !category.products.length && <p style={{ paddingTop: 30, textAlign: "center" }}>No products found.</p>}
          {category.products && category.products.length > 0 && (
            <div className="fcontainer">
              <div className="grid-row">
                {category.products
                  .filter((p) => {
                    if (!Object.keys(selected).filter((k) => selected[k].length).length || !category.filters || !Object.keys(category.filters).length) return true;

                    for (let [key, value] of Object.entries(selected)) {
                      if (!value.length) continue;
                      if (key !== "Brand" && (!p.Attributes || !p.Attributes.length || !category.Attributes || !p.Attributes[0].__component || p.Attributes[0].__component !== category.Attributes)) return false;

                      const acceptableValues = value.map((v) => v.value);
                      const fieldValue = key === "Brand" ? p.brand : p.Attributes[0][key];
                      if (!fieldValue) return false;

                      if (typeof fieldValue === "object") {
                        if (Array.isArray(fieldValue)) {
                          let hasValue = false;
                          for (let index = 0; index < fieldValue.length; index++) {
                            const fV = fieldValue[index];
                            if (fV.id && fV.Title && acceptableValues.includes(fV.id.toString())) {
                              hasValue = true;
                              break;
                            }
                          }
                          if (!hasValue) return false;
                        } else if (!(fieldValue.id && fieldValue.Title && acceptableValues.includes(fieldValue.id.toString()))) return false;
                      } else {
                        if (!acceptableValues.includes(fieldValue.toString())) return false;
                      }
                    }
                    return true;
                  })
                  .map((p, index) => (
                    <ProductSquare key={index} category_uid={category_uid} product={p} />
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
