import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>404 Page not found</title>
      </Helmet>

      <section className="hero error-wrapper">
        <div className="hero-body">
          <h4>Page not found</h4>
          <div>
            <img alt="" src={require("../assets/404.png")} />
          </div>
          <h4>SORRY!</h4>
          <div>
            We couldn't find that page. You may try <Link to="/search">searching</Link> or go to the <Link to="/">home page</Link>.
          </div>
          <button className="button is-large is-primary">We can help</button>
        </div>
      </section>
    </>
  );
}
