import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { separateIt } from "../../utils/misc";
import { contactFormToggle } from "./../../actions/general";

const Footer = ({ general, contactFormToggle }) => {
  return (
    <footer className="hero graybgcolor">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-hidden-mobile">
              {general.header.Logo && <img alt="" src={`${process.env.REACT_APP_API_URL}${general.header.Logo.url}`} />}
              <div className="columns">
                {separateIt(general.footer.Links, 4).map((cats, i) => {
                  return (
                    <div key={i} className="column">
                      <ul className="footer-items">
                        {cats.map((c, j) => {
                          return (
                            <li key={j}>
                              <Link to={general.categories[c.category.UID] ? `${general.categories[c.category.UID].path}` : "/404"}>
                                {c.category.FooterImage && <img alt="" src={`${process.env.REACT_APP_API_URL}${c.category.FooterImage.url}`} />}
                                <span>{c.Title}</span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="column footer-right">
              {general.footer.Title && <h2>{general.footer.Title}</h2>}
              {general.footer.Text && <p className="footer-p">{general.footer.Text}</p>}
              <div>
                {general.footer.ActionButtonText && (
                  <button onClick={contactFormToggle} className="button is-primary is-large">
                    {general.footer.ActionButtonText}
                  </button>
                )}
              </div>
            </div>
            <div className="is-hidden-touch">
              <div className="circle circleBig"></div>
              <div className="circle circleMedium"></div>
              <div className="circle circleSmall"></div>
              <div className="circle circleExtraBig"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p>{general.footer.Address}</p>
        <p id="copyright-string">
          Copyright © {new Date().getFullYear()} Serva-Lite Ltd. All Rights Reserved.{" "}
          <Link style={{ color: "#242424" }} to="/page/4">
            Privacy Policy
          </Link>
        </p>
      </div>
      <div className="creds">
        Made by{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://inspiredpioneers.com/">
          Inspired Pioneers
        </a>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  general: state.general,
});

export default withRouter(connect(mapStateToProps, { contactFormToggle })(Footer));
