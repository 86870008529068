import React, { useEffect } from "react";
//import { Helet } from "react-helmet";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { loadPage } from "../actions/page";
import Title from "./layout/Title";

const Page = ({ loadPage, general, page, history }) => {
  const { id } = useParams();
  useEffect(() => {
    loadPage(id);
  }, [loadPage, id]);
  return !page.loaded ? (
    <></>
  ) : (
    <>
      <Helmet>
        <title>{`${general.home.SEOPrefix}${page.SEO && page.SEO.SEO_Title ? page.SEO.SEO_Title : page.Title}`}</title>
        {page.SEO && page.SEO.SEO_Description && <meta name="description" content={page.SEO.SEO_Description} />}
      </Helmet>
      <Title title={page.Title} />
      <div className="container mainContent">
        <div dangerouslySetInnerHTML={{ __html: page.Text }} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  page: state.page,
  general: state.general,
});

export default withRouter(connect(mapStateToProps, { loadPage })(Page));
