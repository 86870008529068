import React from "react";
import useImage, { Status } from "../utils/useImage.js";

import fallback from "./../assets/noimg.jpg";

export default function Image({ src, alt = "", className = "" }) {
  const [status, img] = useImage(src);

  if (status === Status.LOADING) {
    return <div className="img-loading" />;
  }

  let source;
  if (status === Status.FAILED) {
    source = fallback;
  } else {
    source = src;
  }

  return <img className={`img-loaded${className ? ` ${className}` : ""}`} alt={alt} src={source} />;
}
