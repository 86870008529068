import React from "react";

export default function Fields({ fields }) {
  return (
    <>
      <div id="product-fields">
        <div>
          {fields.map((f, i) => {
            return (
              <p key={i}>
                <label>{f.field}:</label> {f.value}
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
}
