import React, { useCallback, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

export default function Recommended({ recommended }) {
  const [swiped, setSwiped] = useState(false);
  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleOnItemClick = useCallback(
    (e) => {
      if (swiped) {
        e.stopPropagation();
        e.preventDefault();
        setSwiped(false);
      }
    },
    [swiped]
  );

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
        },
      },
    ],
  };
  if (!recommended || !recommended.length) return <></>;
  return (
    <div className="container carouselWrapper recommendedWrapper">
      <h3>Recommended</h3>

      <Slider onSwipe={handleSwiped} className="responsive-carousel" {...settings}>
        {recommended
          .filter((r) => r.categories && r.categories.length)
          .map((r, i) => {
            return (
              <div onClickCapture={handleOnItemClick} key={i} className="wrapper-carousel-img">
                <Link to={`/product/${r.categories[0].UID}/${r.UID}`}>
                  <div className="carouselItem">
                    {r.Gallery && r.Gallery.length > 0 && r.Gallery[0].formats ? <img alt="Recommended Product" src={`${process.env.REACT_APP_API_URL}${r.Gallery[0].formats.thumbnail.url}`} /> : <i className="fas fa-image fa-8x"></i>}
                    <div className="grid-item-content nobg">{r.Title}</div>
                  </div>
                </Link>
              </div>
            );
          })}
      </Slider>
    </div>
  );
}
