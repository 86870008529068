import React from "react";

export default function Title({ title }) {
  return (
    <section className="hero bluebgcolor hero-title-center">
      <div className="hero-body">
        <h2>{title}</h2>
      </div>
      <div className="circle circleBig"></div>
      <div className="circle circleSmall"></div>
    </section>
  );
}
