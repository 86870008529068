import * as types from "../actions/types";

const initialState = {
  loaded: false,
  categories: null,
  title: "",
  text: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.PRODUCT_LOADING: {
      return {
        ...state,
        loaded: false,
      };
    }
    case types.PRODUCT_LOADED: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
      };
    }
    default:
      return state;
  }
}
