import React, { useState } from "react";
import Title from "../layout/Title";
import classNames from "classnames";
import CategorySquare from "../Category/CategorySquare";

export default function CategorySquares({ category, general, category_uid }) {
  const brandsRaw = [];
  category.child_categories.forEach((c) => {
    const cat = general.categories[c.UID];
    if (cat.brand && cat.brand.id) {
      if (!brandsRaw[cat.brand.id]) brandsRaw[cat.brand.id] = cat.brand;
    }
  });

  const [brands, setBrands] = useState(brandsRaw.length ? [...brandsRaw.filter((b) => b)] : null);

  const chooseBrand = (brand) => {
    setBrands(brands.map((b) => (b.id === brand.id ? { ...b, checked: !b.checked } : b)));
  };

  return (
    <div>
      {category.Description ? (
        <section className="hero bluebgcolor">
          <div className="hero-body">
            <div className="container">
              <h2 style={{ marginTop: -10 }}>{category.Title}</h2>
              <p className="series-text" dangerouslySetInnerHTML={{ __html: category.Description }} />
            </div>
          </div>
        </section>
      ) : (
        <Title title={category.Title} />
      )}

      <div className="max-container">
        {brands && brands.length && (
          <div className="choose-brand">
            {brands.map((brand, i) => {
              return (
                <button
                  onClick={() => {
                    chooseBrand(brand);
                  }}
                  className={classNames({ active: brand.checked })}
                  key={i}
                >
                  {brand.Logo && brand.Logo.url ? (
                    <div className="brandwrapper">
                      <img alt={brand.Title} src={`${process.env.REACT_APP_API_URL}${brand.Logo.url}`} />{" "}
                    </div>
                  ) : (
                    <div className="brandwrapper">{brand.Title}</div>
                  )}
                </button>
              );
            })}
          </div>
        )}

        {(!category.child_categories || !category.child_categories.length) && <p style={{ paddingTop: 30, textAlign: "center" }}>No child categories found.</p>}
        {category.child_categories && category.child_categories.length > 0 && (
          <div className="fcontainer">
            <div className="grid-row">
              {category.child_categories
                .filter((c) => (brands && brands.filter((b) => b.checked).length ? brands.find((b) => c.brand && c.brand.id && b.checked && b.id === c.brand.id) : true))
                .map((c, index) => (
                  <CategorySquare category={general.categories[c.UID]} key={index} />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
