import React from "react";
import classNames from "classnames";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export default function SpecsFiles({ attachments }) {
  if (!attachments || !attachments.length) return <></>;
  const cats = [...new Set(attachments.map((a) => a.Type))];
  const categories = cats.map((c) => {
    return { title: c.replace(/([a-z])([A-Z])/g, "$1 $2"), files: attachments.filter((a) => a.Type === c) };
  });
  const ext = (ext) => {
    ext = ext.replace(".", "").toLowerCase();

    const dicts = {
      wodrd: ["doc", "docx", "rtf", "odt", "pages"],
      excel: ["xls", "xslx"],
      powerpoint: ["ppt", "pptx"],
      archive: ["zip", "rar", "7z"],
      pdf: ["pdf"],
      audio: ["wav", "aif", "mp3", "mid"],
      image: ["jpg", "jpeg", "jpe", "jif", "jfif", "jfi", "png", "gif", "webp", "tiff", "tif", "psd", "raw", "arw", "cr2", "nrw", "k25", "bmp", "dib", "heif", "heic", "ind", "indd", "indt", "jp2", "j2k", "jpf", "jpx", "jpm", "mj2", "svg", "svgz", "eps", "ai"],
      video: ["webm", "mpg", "mp2", "mpeg", "mpe", "mpv", "ogg", "mp4", "m4p", "m4v", "avi", "wmv", "mov", "qt", "flv", "swf", "avchd"],
    };
    const dictionary = [];
    Object.keys(dicts).forEach((dict) => {
      dicts[dict].forEach((d) => {
        dictionary[d] = dict;
      });
    });

    return dictionary[ext] || "alt";
  };

  return (
    <section className="spec-wrapper">
      <div className="container">
        <Tabs className={classNames({ "slTabs is-medium": true, singleTab: categories.length === 1 })}>
          <TabList>
            {categories.map((c, i) => {
              return (
                <Tab key={i}>
                  <div className="tabLabel">{c.title}</div>
                </Tab>
              );
            })}
          </TabList>
          <div className="clear" />
          <hr className="tabsHr" />

          {categories.map((c, i) => {
            return (
              <TabPanel key={i}>
                {c.files.map((cgroup) => {
                  return cgroup.FeatureList.map((f, j) => {
                    return (
                      <div key={j}>
                        <p>
                          <a className="fileLink" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_API_URL}${f.url}`}>
                            <i className={`far fa-file-${ext(f.ext)}`}></i>
                            {`${f.name}${f.ext}`}
                          </a>
                        </p>
                      </div>
                    );
                  });
                })}
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
      <div className="clear"></div>
    </section>
  );
}
