import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../ImageLoader";

export default function CategorySquare({ category }) {
  return (
    <div className="grid-item CategorySquares">
      <Link className="wrapping-link" to={category.path}></Link>
      <div className="grid-item-wrapper">
        <div className="grid-item-container category-list-item">
          <div className="imgwrapper">{category.Image ? <ImageLoader alt="Category" src={`${process.env.REACT_APP_API_URL}${category.Image.url}`} /> : <i className="fas fa-image fa-9x"></i>}</div>
          <div className="grid-item-content">
            <span className="item-title">{category.Title}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
